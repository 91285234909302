import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./gallery.css";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper/modules";

const Gallery = (props) => {
  const images = [props.image1, props.image2, props.image3, props.image4, props.image5, props.image6, props.image7].filter(Boolean);

  return (
    <div className="relative min-h-screen bg-black py-20">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-5xl font-bold text-primaryYellow text-center mb-16"
        >
          {props.title}
          <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-transparent via-primaryYellow to-transparent" />
        </motion.h1>

        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 200,
            modifier: 2.5,
            slideShadows: true,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="w-full max-w-7xl mx-auto"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="w-[300px] h-[400px] md:w-[400px] md:h-[500px]">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="relative w-full h-full rounded-2xl overflow-hidden shadow-2xl"
              >
                <img 
                  src={image} 
                  alt={`slide_image_${index + 1}`}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent" />
              </motion.div>
            </SwiperSlide>
          ))}

          <div className="slider-controler mt-8">
            <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="swiper-button-prev slider-arrow"
            >
              <FaAngleLeft className="text-2xl" />
            </motion.div>
            <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="swiper-button-next slider-arrow"
            >
              <FaAngleRight className="text-2xl" />
            </motion.div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default Gallery;
