import React from "react";
import { motion } from "framer-motion";

const SecondBanner = (props) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="relative h-screen w-full overflow-hidden"
    >
      {/* Background Image with Enhanced Gradient */}
      <div
        className="absolute inset-0 w-full h-full transform scale-105 transition-transform duration-1000"
        style={{
          backgroundImage: `
            linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.5) 40%,
              rgba(0, 0, 0, 0.8) 80%,
              rgba(0, 0, 0, 1)
            ),
            url(${props.image})
          `,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      />

      {/* Decorative Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
      </div>

      {/* Content Container */}
      <div className="relative h-full flex flex-col items-center justify-center px-6 md:px-12 lg:px-24">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="max-w-6xl mx-auto text-center"
        >
          {/* Title */}
          <motion.h1
            style={{ fontFamily: "Comfortaa" }}
            className="text-3xl md:text-4xl lg:text-5xl text-white font-bold mb-8
              relative inline-block"
          >
            {props.page}
            {/* Underline Effect */}
            <motion.div 
              className="absolute -bottom-3 left-0 w-full h-1 bg-primaryYellow"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 0.8, delay: 0.5 }}
            />
          </motion.h1>

          {/* Description */}
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-lg md:text-xl lg:text-2xl text-white/90 
              max-w-3xl mx-auto leading-relaxed italic font-light
              tracking-wide"
          >
            {props.pagedesc}
          </motion.p>

          {/* Decorative Line */}
          <motion.div 
            className="w-[2px] h-20 bg-gradient-to-b from-primaryYellow via-primaryYellow to-transparent mx-auto mt-12"
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          />
        </motion.div>
      </div>

      {/* Bottom Gradient Overlay */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent" />
    </motion.div>
  );
};

export default SecondBanner;
