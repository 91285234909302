import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import MembershipBanner from "../../assets/images/pricing-banner.jpeg";
import SimplePricingCard from "./simplepricingcard";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const Memberships = () => {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState("woman");
  const [isVisible, setIsVisible] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
    }
  }, [inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  return (
    <motion.div 
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={containerVariants}
      className="relative min-h-screen py-20 overflow-hidden bg-black"
    >
      {/* Background Image with Better Mobile Handling */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${MembershipBanner})`,
          backgroundAttachment: "fixed",
        }}
      />
      
      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/95 to-black/85" />

      {/* Decorative Elements with Better Mobile Visibility */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-full md:w-1/3 h-1/3 
          bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent 
          blur-3xl opacity-30" 
        />
        <div className="absolute bottom-0 right-0 w-full md:w-1/3 h-1/3 
          bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent 
          blur-3xl opacity-30" 
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          className="flex flex-col items-center space-y-8 mb-16"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h1 
            style={{ fontFamily: "Comfortaa" }}
            className="text-3xl md:text-5xl text-white font-bold text-center"
          >
            {t("Memberships.title")}
          </h1>
          <p 
            style={{ fontFamily: "Comfortaa" }}
            className="text-lg md:text-xl text-white/80 text-center max-w-2xl px-4"
          >
            {t("Memberships.description")}
          </p>
          <motion.div 
            className="w-[2px] h-20 bg-gradient-to-b from-primaryYellow via-primaryYellow to-transparent"
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          />
        </motion.div>

        {/* Enhanced Tab Buttons with Better Mobile Spacing */}
        <div className="flex justify-center space-x-6 md:space-x-12 mb-16">
          {["woman", "man"].map((tab) => (
            <motion.button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`relative px-4 md:px-6 py-2 text-lg md:text-xl text-white transition-all duration-300
                ${activeTab === tab ? "text-primaryYellow" : "hover:text-primaryYellow/80"}`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t(`Memberships.${tab}`)}
              {activeTab === tab && (
                <motion.div 
                  className="absolute bottom-0 left-0 w-full h-0.5 bg-primaryYellow"
                  layoutId="activeTab"
                />
              )}
            </motion.button>
          ))}
        </div>

        {/* Cards Grid with Improved Mobile Layout */}
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 justify-items-center"
          variants={containerVariants}
        >
          {activeTab === "woman" ? (
            <>
              <SimplePricingCard
                title={t("Memberships.monthly")}
                offer1="1200"
                offer2="MKD"
                button_txt={t("Memberships.buttonText")}
              />
              <SimplePricingCard
                title={t("Memberships.3months")}
                offer1="3000"
                offer2="MKD"
                button_txt={t("Memberships.buttonText")}
              />
              <SimplePricingCard
                title={t("Memberships.12months")}
                offer1="10000"
                offer2="MKD"
                button_txt={t("Memberships.buttonText")}
              />
            </>
          ) : (
            <>
              <SimplePricingCard
                title={t("Memberships.monthly")}
                offer1="1500"
                offer2="MKD"
                button_txt={t("Memberships.buttonText")}
              />
              <SimplePricingCard
                title={t("Memberships.3months")}
                offer1="4000"
                offer2="MKD"
                button_txt={t("Memberships.buttonText")}
              />
              <SimplePricingCard
                title={t("Memberships.12months")}
                offer1="15000"
                offer2="MKD"
                button_txt={t("Memberships.buttonText")}
              />
            </>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Memberships;
