import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import facebookIcon from '../../assets/icons/facebook.png';
import instagramIcon from '../../assets/icons/instagram.png';
import locationIcon from "../../assets/icons/location (2).png";
import phoneIcon from "../../assets/icons/phone-2.png";
import mailIcon from "../../assets/icons/email (1).png";

const ContactUsPage = () => {
  const { t } = useTranslation("global");

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="relative min-h-screen bg-black py-20 px-4">
      {/* Enhanced Background Effects */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-1/2 h-1/2 bg-gradient-to-br from-primaryYellow/30 via-primaryYellow/10 to-transparent blur-3xl opacity-30 animate-pulse" />
        <div className="absolute bottom-0 right-0 w-1/2 h-1/2 bg-gradient-to-tl from-primaryYellow/30 via-primaryYellow/10 to-transparent blur-3xl opacity-30 animate-pulse" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-[radial-gradient(circle,transparent,#000_70%)]" />
      </div>

      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="container mx-auto max-w-7xl"
      >
        <motion.div 
          className="bg-black/40 backdrop-blur-md text-white p-8 md:p-12 rounded-3xl shadow-2xl 
            border border-primaryYellow/20 hover:border-primaryYellow/40 transition-all duration-500
            relative overflow-hidden"
        >
          {/* Decorative Elements */}
          <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-primaryYellow/40 to-transparent" />
          <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-primaryYellow/40 to-transparent" />

          <div className="flex flex-col md:flex-row justify-between gap-12 md:gap-20">
            {/* Contact Details Section */}
            <motion.div variants={itemVariants} className="md:w-1/2 space-y-10">
              <motion.h2 
                variants={itemVariants}
                className="text-4xl md:text-6xl font-bold text-primaryYellow relative inline-block"
                style={{ fontFamily: 'Comfortaa' }}
              >
                {t('ContactUs.key')}
                <div className="absolute -bottom-4 left-0 w-full h-1 bg-gradient-to-r from-primaryYellow via-primaryYellow/50 to-transparent" />
              </motion.h2>

              {/* Contact Items */}
              <div className="space-y-8">
                {[
                  { icon: locationIcon, title: t('ContactUs.Location.location'), content: t('ContactUs.Location.address') },
                  { icon: phoneIcon, title: t('ContactUs.Contact.phone'), content: '+38971255244', isLink: true, href: 'tel:+38971255244' },
                  { icon: mailIcon, title: t('ContactUs.Contact.email'), content: 'info@californiafitnescenter.net', isLink: true, href: 'mailto:info@californiafitnescenter.net' }
                ].map((item, index) => (
                  <motion.div 
                    key={index}
                    variants={itemVariants}
                    className="group hover:bg-primaryYellow/5 p-4 rounded-2xl"
                  >
                    <h3 className="flex items-center text-2xl md:text-4xl mb-3 text-primaryYellow/80">
                      <motion.img 
                        whileHover={{ rotate: 15 }}
                        src={item.icon} 
                        alt={`${item.title} Icon`} 
                        className="w-8 h-8 mr-3 opacity-70 group-hover:opacity-100 transition-opacity duration-300"
                      />
                      {item.title}
                    </h3>
                    {item.isLink ? (
                      <a 
                        href={item.href}
                        className="text-xl text-white/80 hover:text-primaryYellow transition-colors duration-300"
                      >
                        {item.content}
                      </a>
                    ) : (
                      <p className="text-xl text-white/80">{item.content}</p>
                    )}
                  </motion.div>
                ))}
              </div>

              {/* Social Media Section */}
              <div className="text-center mt-8">
                <p className="text-lg mb-4 text-white/80">{t('ContactUs.socialMedia')}</p>
                <div className="flex space-x-6">
                  {[
                    { icon: facebookIcon, href: 'https://www.facebook.com/californiafitnesscenter1', alt: 'Facebook' },
                    { icon: instagramIcon, href: 'https://www.instagram.com/californiafitnescenter/', alt: 'Instagram' }
                  ].map((social, index) => (
                    <motion.a
                      key={index}
                      whileHover={{ scale: 1.2, rotate: 5 }}
                      whileTap={{ scale: 0.9 }}
                      href={social.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transform transition-all duration-300"
                    >
                      <img 
                        src={social.icon} 
                        alt={social.alt} 
                        className="w-12 h-12 opacity-70 hover:opacity-100 transition-opacity duration-300" 
                      />
                    </motion.a>
                  ))}
                </div>
              </div>
            </motion.div>

            {/* Location Section */}
            <motion.div variants={itemVariants} className="md:w-1/2 mt-16 md:mt-0 flex flex-col items-center md:items-start md:ml-4">
              <motion.h2 
                variants={itemVariants}
                className="text-4xl md:text-6xl font-bold mb-10 text-primaryYellow"
                style={{ fontFamily: 'Comfortaa' }}
              >
                {t('Location.key')}
              </motion.h2>
              <motion.div 
                variants={itemVariants}
                className="w-full rounded-2xl overflow-hidden shadow-2xl 
                  hover:shadow-primaryYellow/20 transition-all duration-500"
              >
                <iframe
                  className="w-full h-[500px] border-0 outline-none"
                  title="Google Maps Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2677.561843032807!2d21.710561375573892!3d42.13311859940979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13544fcd305121a7%3A0x1ab9dcd1d0cb283c!2sPazar%2C%20Hristijan%20Todorovski%20Karposh%2C%20Kumanovo%2C%20North%20Macedonia!5e1!3m2!1sen!2s!4v1715014299341!5m2!1sen!2s"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ContactUsPage;
