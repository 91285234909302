import React from "react";
import { motion } from "framer-motion";

const ServiceCard = (props) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05, y: -5 }}
      whileTap={{ scale: 0.95 }}
      className="flex flex-col h-[300px] p-8 rounded-2xl bg-black/60 backdrop-blur-lg
        border-2 border-primaryYellow/30 hover:border-primaryYellow
        shadow-lg hover:shadow-2xl hover:shadow-primaryYellow/20
        transition-all duration-500 group relative overflow-hidden"
    >
      {/* Enhanced Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-primaryYellow/10 via-transparent to-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
      
      {/* Icon Container */}
      <div className="relative mb-6">
        <div className="absolute inset-0 bg-primaryYellow/20 blur-2xl transform group-hover:scale-[2] transition-transform duration-500" />
        <div className="relative w-20 h-20 mx-auto">
          <img
            src={props.icon}
            alt="icon"
            className="w-full h-full p-4 rounded-xl bg-black/40 
              border-2 border-primaryYellow/30 group-hover:border-primaryYellow
              transition-all duration-500 group-hover:rotate-12 group-hover:scale-110"
          />
          {/* Glow Effect */}
          <div className="absolute inset-0 bg-primaryYellow/20 rounded-xl filter blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
        </div>
      </div>

      <h3
        style={{ fontFamily: "Comfortaa" }}
        className="text-2xl font-bold mb-4 text-white group-hover:text-primaryYellow text-center
          transition-colors duration-500 relative"
      >
        {props.title}
        {/* Underline effect */}
        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-0 h-0.5 bg-primaryYellow
          group-hover:w-1/2 transition-all duration-500" />
      </h3>

      <p className="text-base text-white/70 group-hover:text-white/90 text-center flex-grow
        transition-colors duration-500 leading-relaxed">
        {props.text}
      </p>

      {/* Enhanced Decorative Elements */}
      <div className="absolute -bottom-1 -right-1 w-16 h-16 border-b-2 border-r-2 border-primaryYellow/30 
        group-hover:border-primaryYellow group-hover:scale-110 transition-all duration-500" />
      <div className="absolute -top-1 -left-1 w-16 h-16 border-t-2 border-l-2 border-primaryYellow/30 
        group-hover:border-primaryYellow group-hover:scale-110 transition-all duration-500" />
    </motion.div>
  );
};

export default ServiceCard;
