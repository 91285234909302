import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import instagram from "../../assets/icons/instagram.png"
import facebook from "../../assets/icons/facebook.png"
import phone from "../../assets/icons/phone-2.png"

const HistoryCard = ({ imageSrc, title, date, paragraph, reverseOrder }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.7,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`flex items-center justify-center min-h-[750px] sm:min-h-[650px] rounded-2xl
        bg-black/40 backdrop-blur-sm border border-primaryYellow/20 
        hover:border-primaryYellow/50 transition-all duration-500 mx-4 
        shadow-lg hover:shadow-xl hover:shadow-primaryYellow/20 
        flex-col md:flex-row ${reverseOrder ? "" : "md:flex-row-reverse"}
        p-4 md:p-8 gap-6 md:gap-12`}
    >
      {/* Image Container */}
      <div
        className={`relative w-full h-[500px] sm:h-[400px] md:w-[40%] md:h-[85%]
          ${!reverseOrder ? "md:justify-start" : "md:justify-end"}
          duration-300 ${isVisible ? "opacity-100" : "opacity-0"} ${
          reverseOrder
            ? isVisible
              ? "md:translate-x-0"
              : "md:translate-x-20"
            : isVisible
            ? "md:translate-x-0"
            : "md:-translate-x-20"
        }`}
      >
        <div className="relative h-full w-full overflow-hidden rounded-xl 
          shadow-lg hover:shadow-xl hover:shadow-primaryYellow/20 
          transition-all duration-500">
          <img
            src={imageSrc}
            alt="Timeline"
            className="object-cover w-full h-full
              transform hover:scale-105 transition-transform duration-700"
          />
          <div className="absolute inset-0 bg-gradient-to-t 
            from-black/60 via-transparent to-transparent" />
        </div>
      </div>

      {/* Enhanced Timeline Line */}
      <div className="relative flex items-center justify-center 
        h-0.5 w-full md:w-0.5 md:h-96 bg-gradient-to-r md:bg-gradient-to-b 
        from-transparent via-primaryYellow to-transparent my-4 md:my-0 md:mx-4">
        <div
          className={`transition-all duration-500 absolute top-1/2 left-1/2 
            transform -translate-x-1/2 -translate-y-1/2 
            ${isVisible ? `bg-primaryYellow scale-100` : "bg-richBlack scale-0"}
            w-6 h-6 md:w-12 md:h-12 rounded-full border-4 border-primaryYellow
            shadow-lg shadow-primaryYellow/30`}
        />
      </div>

      {/* Content Container */}
      <div
        className={`w-full md:w-[40%] duration-300 
          ${isVisible ? "opacity-100" : "opacity-0"} ${
          reverseOrder
            ? isVisible
              ? "md:translate-x-0"
              : "md:-translate-x-20"
            : isVisible
            ? "md:translate-x-0"
            : "md:translate-x-20"
        }`}
      >
        <div className="flex flex-col md:flex-row justify-between gap-2 md:gap-4 mb-4 md:mb-6">
          <h2
            style={{ fontFamily: "Comfortaa" }}
            className="text-xl md:text-4xl font-bold text-primaryYellow
              transform hover:scale-105 transition-transform duration-300"
          >
            {title}
          </h2>
          <span
            style={{ fontFamily: "Comfortaa" }}
            className="text-sm md:text-md text-white/60 italic uppercase
              tracking-wider"
          >
            {date}
          </span>
        </div>
        
        <p className="text-white/80 text-sm md:text-base leading-relaxed
          hover:text-white transition-colors duration-300">
          {paragraph}
        </p>

        {/* Social Links */}
        <div className="mt-6 md:mt-8">
          <div className="flex flex-row items-center gap-4">
            {[
              { icon: instagram, href: "https://instagram.com", label: "Instagram" },
              { icon: facebook, href: "https://facebook.com", label: "Facebook" },
              { icon: phone, href: "tel:+389", label: "Phone" }
            ].map((social, index) => (
              <a
                key={index}
                href={social.href}
                aria-label={social.label}
                className="transform hover:scale-110 hover:-translate-y-1
                  transition-all duration-300"
              >
                <img 
                  src={social.icon}
                  alt={social.label}
                  className="w-6 h-6 md:w-8 md:h-8 opacity-40 hover:opacity-100 
                    transition-opacity duration-300"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryCard;