import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AmenitiesCard = ({ image_path, buttonLabel, className = "" }) => {
  const { t } = useTranslation("global");
  return (
    <motion.div 
      className={`relative group w-full max-w-sm rounded-xl overflow-hidden 
        shadow-xl hover:shadow-2xl transition-all duration-500 ${className}`}
      whileHover={{ scale: 1.02 }}
    >
      <div className="aspect-[3/4] relative overflow-hidden">
        <img
          src={image_path}
          alt={buttonLabel}
          className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 p-6">
          <h3 
            style={{ fontFamily: "Comfortaa" }}
            className="text-2xl font-bold text-white mb-2">{buttonLabel}</h3>
          <button 
            style={{ fontFamily: "Comfortaa" }}
            className="px-6 py-2 bg-primaryYellow/90 hover:bg-primaryYellow 
            text-black font-semibold rounded-lg transition-all duration-300 
            transform hover:scale-105">
            {t("ImageCarousel.buttonText")}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default AmenitiesCard;
