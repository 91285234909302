import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

const SimplePricingCard = (props) => {
  const [t] = useTranslation("global");

  return (
    <motion.div
      whileHover={{ y: -10, scale: 1.02 }}
      className="flex flex-col items-center w-full max-w-sm bg-gradient-to-b from-zinc-900 to-black
        rounded-2xl overflow-hidden shadow-xl hover:shadow-2xl hover:shadow-primaryYellow/20
        border border-primaryYellow/30 hover:border-primaryYellow
        transition-all duration-500 backdrop-blur-lg"
    >
      {/* Card Header */}
      <div className="w-full bg-primaryYellow/10 p-6 text-center">
        <h2
        style={{ fontFamily: "Comfortaa" }}
        className="text-2xl font-bold text-white mb-2">
          {props.title}
        </h2>
      </div>

      {/* Price Section */}
      <div className="flex items-baseline justify-center gap-2 py-8 px-6">
        <span 
        style={{ fontFamily: "Comfortaa" }}
        className="text-6xl font-bold text-primaryYellow">
          {props.offer1}
        </span>
        <span 
        style={{ fontFamily: "Comfortaa" }}
        className="text-2xl text-white/80">
          {props.offer2}
        </span>
      </div>

      {/* Features */}
      <div className="w-full px-6 py-4">
        <div 
        style={{ fontFamily: "Comfortaa" }}
        className="text-center text-white/70 italic mb-8">
          + {t("Memberships.bodyAnalysis")}
        </div>
      </div>

      {/* Button */}
      <div className="w-full px-6 pb-8">
        <motion.a
        style={{ fontFamily: "Comfortaa" }}
          href="/contact"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="block w-full py-3 px-6 text-center rounded-xl
            bg-gradient-to-r from-primaryYellow to-yellow-500
            text-black font-bold text-lg
            transform transition-all duration-300
            hover:shadow-lg hover:shadow-primaryYellow/50"
        >
          {props.button_txt}
        </motion.a>
      </div>

      {/* Decorative Elements */}
      <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-primaryYellow/30 rounded-tr-2xl" />
      <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-primaryYellow/30 rounded-bl-2xl" />
    </motion.div>
  );
};

export default SimplePricingCard;