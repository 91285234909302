import React, { useState, useEffect } from "react";
import Card from "./card";
import BgBanner from "../../assets/images/california-2.jpeg";
import BgBanner1 from "../../assets/images/california-3.jpeg";
import BgBanner2 from "../../assets/images/california-4.jpeg";
import BgBanner3 from "../../assets/images/california-5.jpeg";
import NumberFlipper from "../NumberFlipper/numberFlipper";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const CardSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const [t] = useTranslation("global");

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 100,
      scale: 0.9
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
        duration: 0.8
      }
    }
  };

  const titleVariants = {
    hidden: { 
      opacity: 0,
      y: -30,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20,
        duration: 0.6
      }
    }
  };

  return (
    <div className="relative w-full min-h-screen bg-black py-32 overflow-hidden">
      {/* Animated Background with Enhanced Parallax */}
      <div 
        className="absolute inset-0 bg-fixed transition-transform duration-[2000ms]"
        style={{
          backgroundImage: `linear-gradient(to bottom, 
            rgba(0, 0, 0, 0.95), 
            rgba(0, 0, 0, 0.75),
            rgba(0, 0, 0, 0.95)), url(${BgBanner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          transform: isVisible ? 'scale(1.1)' : 'scale(1)'
        }}
      />

      {/* Enhanced Decorative Elements */}
      <div className="absolute inset-0 opacity-30">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
          className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-primaryYellow/30 via-transparent to-transparent blur-3xl"
        />
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5, delay: 0.3 }}
          className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-primaryYellow/30 via-transparent to-transparent blur-3xl"
        />
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4">
        {/* Enhanced Title Section */}
        <motion.div
          variants={titleVariants}
          initial="hidden"
          animate="visible"
          className="text-center mb-24"
        >
          <div className="inline-block relative">
            <motion.h2 
             style={{ fontFamily: "Comfortaa" }}
              className="text-4xl font-bold text-white py-6 px-12 border-2 border-primaryYellow rounded-2xl bg-black/70 backdrop-blur-sm shadow-lg"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              {t("CardsSection.title")}
            </motion.h2>
            <motion.div 
              className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-primaryYellow rounded-full"
              initial={{ width: 0 }}
              animate={{ width: 96 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            />
          </div>
        </motion.div>

        {/* Enhanced Cards Container */}
        <motion.div
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 justify-items-center relative"
        >
          {/* Cards */}
          <motion.div variants={cardVariants} whileHover={{ scale: 1.05 }} className="hover:z-10">
            <Card
              backgroundImage={BgBanner1}
              title={
                <div className="flex items-center justify-center">
                  <NumberFlipper n={1200} />
                  <span className="ml-2 mt-4 text-3xl">m<sup>2</sup></span>
                </div>
              }
              button_txt={t("CardsSection.area")}
            />
          </motion.div>

          <motion.div variants={cardVariants} whileHover={{ scale: 1.05 }} className="hover:z-10 md:mt-12">
            <Card
              backgroundImage={BgBanner2}
              title={<NumberFlipper n={4} />}
              button_txt={t("CardsSection.pts")}
            />
          </motion.div>

          <motion.div variants={cardVariants} whileHover={{ scale: 1.05 }} className="hover:z-10">
            <Card
              backgroundImage={BgBanner3}
              title={<NumberFlipper n={6} />}
              button_txt={t("CardsSection.categories")}
            />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default CardSection;

// https://vimeo.com/412102529
