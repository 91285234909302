import React from "react";
import HistoryCard from "./historyCard";
import IsmailShefketi from "../../assets/images/IsmailShefketi.jpg";
import EleonoraKiprijanovsk from "../../assets/images/EleonoraKiprijanovska.jpg";
import EndritAziri from "../../assets/images/EndritAziri.jpg";
import TeaJovancevska from "../../assets/images/teajovancevska.jpeg";
import { useTranslation } from "react-i18next";

const HistorySection = () => {
  const { t } = useTranslation("global");

  return (
    <div className="relative min-h-screen bg-black py-20">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 
          bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent 
          blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 
          bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent 
          blur-3xl opacity-30" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Title Section */}
        <div className="text-center mb-20">
          <h1
            style={{ fontFamily: "Comfortaa" }}
            className="text-5xl md:text-6xl font-bold text-primaryYellow mb-6
              relative inline-block"
          >
            {t("HistorySection.title")}
            {/* Underline Effect */}
            <div className="absolute -bottom-4 left-0 w-full h-1 
              bg-gradient-to-r from-transparent via-primaryYellow to-transparent" />
          </h1>
        </div>

        {/* Cards Container */}
        <div className="space-y-16">
          <HistoryCard
            imageSrc={IsmailShefketi}
            title={t("HistorySection.trainers.ismailShefketi.title")}
            date={t("HistorySection.trainers.ismailShefketi.date")}
            paragraph={t("HistorySection.trainers.ismailShefketi.paragraph")}
            reverseOrder={false}
          />
          <HistoryCard
            imageSrc={EleonoraKiprijanovsk}
            title={t("HistorySection.trainers.eleonoraKiprijanovska.title")}
            date={t("HistorySection.trainers.eleonoraKiprijanovska.date")}
            paragraph={t(
              "HistorySection.trainers.eleonoraKiprijanovska.paragraph"
            )}
            reverseOrder={true}
          />
          <HistoryCard
            imageSrc={EndritAziri}
            title={t("HistorySection.trainers.endritAziri.title")}
            date={t("HistorySection.trainers.endritAziri.date")}
            paragraph={t("HistorySection.trainers.endritAziri.paragraph")}
            reverseOrder={false}
          />
          <HistoryCard
            imageSrc={TeaJovancevska}
            title={t("HistorySection.trainers.teaJovancevska.title")}
            date={t("HistorySection.trainers.teaJovancevska.date")}
            paragraph={t("HistorySection.trainers.teaJovancevska.paragraph")}
            reverseOrder={true}
          />
        </div>
      </div>
    </div>
  );
};

export default HistorySection;
