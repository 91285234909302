import React from 'react';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import Image11 from "../../assets/images/california-2.jpeg";
import Image22 from "../../assets/images/california-4.jpeg";
import Image33 from "../../assets/images/california-3.jpeg";
import Image44 from "../../assets/images/california-6.jpeg";
import Image55 from "../../assets/images/california-9.jpeg";
import Image66 from "../../assets/images/califronia-12.jpeg";
import Image77 from "../../assets/images/california-10.jpeg";
import Image88 from "../../assets/images/california-11.jpeg";
import Image99 from "../../assets/images/california-13.jpeg";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const LightboxGallery = () => {
  const { t } = useTranslation();
  const images = [
    Image11, Image22, Image33, Image44, Image55, 
    Image66, Image77, Image88, Image99
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="relative min-h-screen bg-black py-20">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
      </div>

      <motion.div 
        className="container mx-auto px-4 relative z-10"
        initial="hidden"
        animate="show"
        variants={container}
      >
        <motion.h1 
          style={{fontFamily: "Comfortaa"}}
          className="text-5xl font-bold text-primaryYellow text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Gallery
          <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-transparent via-primaryYellow to-transparent" />
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {images.map((image, index) => (
            <motion.div
              key={index}
              variants={item}
              className="relative aspect-square overflow-hidden rounded-xl shadow-xl 
                hover:shadow-2xl hover:shadow-primaryYellow/20 transition-all duration-300"
            >
              <motion.img 
                src={image}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-full object-cover cursor-pointer"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <motion.div 
                className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent 
                  opacity-0 hover:opacity-100 transition-opacity duration-300"
                whileHover={{ opacity: 1 }}
              />
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default LightboxGallery;