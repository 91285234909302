import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import YogaPic from "../../assets/images/membership-background.jpeg";
import Biceps from "../../assets/icons/biceps.png";
import CheckList from "../../assets/icons/check-list.png";
import Meditation from "../../assets/icons/meditation.png";
import Schedule from "../../assets/icons/schedule.png";
import ServiceCard from "./service-card";
import CaliforniaVideo from "../../assets/videos/CaliforniaVideo.mp4"
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const GroupSection = () => {
  const [t] = useTranslation("global");
  const [isVisible, setIsVisible] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the component is visible
  });
  const handleVideoClick = (e) => {
    const videoElement = e.target;
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) { /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) { /* IE/Edge */
      videoElement.msRequestFullscreen();
    }
  };

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
    }
  }, [inView]); // Run only when 'inView' changes

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="relative w-full min-h-screen bg-black py-32 overflow-hidden">
      {/* Enhanced Background Effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
      
      {/* Decorative Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
      </div>
      
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={containerVariants}
        className="container max-w-7xl mx-auto px-4 relative z-10"
      >
        {/* Enhanced Title Section */}
        <motion.div 
          variants={cardVariants}
          className="text-center mb-24"
        >
          <h1
            style={{ fontFamily: "Comfortaa" }}
            className="text-5xl md:text-6xl font-bold text-white mb-8 relative inline-block"
          >
            {t("GroupSection.title")}
            <motion.div 
              className="absolute -bottom-4 left-0 w-full h-1 bg-primaryYellow rounded-full"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 1, delay: 0.5 }}
            />
          </h1>
          
          <p className="text-xl text-white/80 max-w-3xl mx-auto leading-relaxed mt-6">
            {t("GroupSection.description")}
          </p>
        </motion.div>

        {/* Enhanced Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-6 mb-24">
          <motion.div variants={cardVariants}>
            <ServiceCard
              icon={Biceps}
              title={t("GroupSection.Card1.title")}
              text={t("GroupSection.Card1.description")}
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <ServiceCard
              icon={CheckList}
              title={t("GroupSection.Card2.title")}
              text={t("GroupSection.Card2.description")}
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <ServiceCard
              icon={Meditation}
              title={t("GroupSection.Card3.title")}
              text={t("GroupSection.Card3.description")}
            />
          </motion.div>
          <motion.div variants={cardVariants}>
            <ServiceCard
              icon={Schedule}
              title={t("GroupSection.Card4.title")}
              text={t("GroupSection.Card4.description")}
            />
          </motion.div>
        </div>

        {/* Enhanced Video Section */}
        <motion.div 
          variants={cardVariants}
          className="relative rounded-2xl overflow-hidden group"
        >
          <video
            autoPlay
            muted
            loop
            playsInline
            onClick={handleVideoClick}
            className="w-full rounded-2xl border-2 border-primaryYellow/30 group-hover:border-primaryYellow
              shadow-lg group-hover:shadow-2xl group-hover:shadow-primaryYellow/20 transition-all duration-500
              transform group-hover:scale-[1.01]"
          >
            <source src={CaliforniaVideo} type="video/mp4" />
          </video>
          
          {/* Enhanced Video Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-transparent to-transparent 
            opacity-0 group-hover:opacity-100 transition-all duration-500 
            flex items-end justify-center pb-12">
            <span className="text-white text-xl font-light tracking-wider">
              Click to view fullscreen
            </span>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default GroupSection;
