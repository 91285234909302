import React from "react";
import FooterBanner from "../../assets/images/pricing-banner.jpeg";
import Location from "../../assets/icons/location.png";
import Phone from "../../assets/icons/phone.png";
import Mail from "../../assets/icons/mail.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18next] = useTranslation("global");

  return (
    <div className="flex h-auto justify-center items-center flex-col">
      <div
        className="w-full h-auto  relative bg-opacity-0"
        style={{
          backgroundImage: `linear-gradient(rgba(10, 10, 10, .90), rgba(10, 10, 10, .90)), url(${FooterBanner})`,

          backgroundRepeat: "repeat, no-repeat",
          backgroundSize: "auto, cover",
        }}
      >
        <div className="lg:max-w-[1280px] md:max-w-[800px] max-w-[300px] mx-auto flex flex-col lg:flex-row justify-between border-b-[2px] border-white mb-2 ">
          <div className="mt-24 mb-12  lg:w-[40%]">
            <h1
              style={{ fontFamily: "Comfortaa" }}
              className="text-3xl text-center lg:text-start text-white hover:text-primaryYellow"
            >
              CALIFORNIA FITNESS CENTER
            </h1>
            <p className="text-l text-center lg:text-start text-white mt-10 font-thin">
              {t("Footer.paragraph")}
            </p>

            <div className="flex justify-between w-[80%] mx-auto lg:mx-0 lg:w-[30%] mt-20">
              <a href="https://www.instagram.com/californiafitnescenter/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-white text-xl  border-white rounded-full border-2 p-2 hover:bg-white hover:text-black hover:border-white hover:animate-bounce hover:cursor-pointer shadow-lg shadow-zinc-500"
                />
              </a>
              <a href="https://www.facebook.com/californiafitnesscenter1/">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-white text-xl  border-white rounded-full border-2 p-2 px-3  hover:bg-white hover:border-white hover:text-black hover:animate-bounce shadow-lg shadow-zinc-500"
                />
              </a>
              {/* <FontAwesomeIcon
                icon={faXTwitter}
                className="text-white text-xl  border-white rounded-full border-2 p-2 hover:bg-white hover:text-black hover:border-white hover:animate-bounce shadow-lg shadow-zinc-500"
              /> */}
            </div>

            <div className="flex flex-col mt-16">
              <p
                style={{ fontFamily: "Comfortaa" }}
                className="text-l text-center lg:text-start text-white font-semibold"
              >
                {t("Footer.HoursOfOperation")}
              </p>
              <p className="text-l text-center lg:text-start text-white font-thin">
                {t("Footer.Monday-Thursday")}: 7:00am - 00:00am
              </p>
              {/* <p className="text-l text-center lg:text-start text-white font-thin">
                {t("Footer.Friday")}: 5:30am - 9pm
              </p>
              <p className="text-l text-center lg:text-start text-white font-thin">
                {t("Footer.Saturday&Sunday")}: 8am - 8pm
              </p> */}
            </div>
          </div>

          {/* <div className="mt-16 mb-12 lg:w-[25%] w-full flex flex-col h-40 justify-between">
            <p
              style={{ fontFamily: "Comfortaa" }}
              className="text-l text-center lg:text-start text-white font-bold"
            >
              {t("Footer.UsefulPDFs")}
            </p>
            <p className="text-l text-center lg:text-start text-white font-thin hover:underline hover:underline-offset-4">
              {t("Footer.PrivacyPolicy&TermsOfUse")}
            </p>
            <p className="text-l text-center lg:text-start text-white font-thin hover:underline hover:underline-offset-4">
              {t("Footer.MembershipAgreement")}
            </p>
            <p className="text-l text-center lg:text-start text-white font-thin hover:underline hover:underline-offset-4">
              {t("Footer.MembershipRules")}
            </p>
          </div> */}

          <div className="mt-16 mb-12 lg:w-[25%] w-full flex flex-col h-44 justify-between">
            <p
              style={{ fontFamily: "Comfortaa" }}
              className="text-l text-center lg:text-start text-white font-bold mb-4"
            >
              {t("Footer.ContactUs")}
            </p>
            <div className="flex lg:w-[85%] w-full items-center justify-center lg:justify-start ">
              <img src={Location} alt="" className="w-6 h-6" />
              <p className="text-l text-center lg:text-start px-3 text-white font-thin underline underline-offset-2 hover:underline-offset-4 hover:scale-[1.1] -mt-1">
                Hristijan Todorovski, Karposh 60, Kumanove 1300
              </p>
            </div>
            <div className="flex lg:w-[85%] w-full items-center justify-center lg:justify-start">
              <img src={Phone} alt="" className="w-6 h-6" />
              <p className="text-l text-center lg:text-start px-3 text-white font-thin underline underline-offset-2 hover:underline-offset-4 hover:scale-[1.1] -mt-1">
                +38971255244
              </p>
            </div>
            <div className="flex lg:w-[85%] w-full items-center justify-center lg:justify-start">
              <img src={Mail} alt="" className="w-6 h-6" />
              <p className="text-l text-center lg:text-start px-3 text-white font-thin underline underline-offset-2 hover:underline-offset-4 hover:scale-[1.1] -mt-1">
                info@californiafitnescenter.net
              </p>
            </div>
          </div>
        </div>
        <div className="lg:max-w-[1280px] md:max-w-[800px] max-w-[300px] mx-auto">
          <p
            style={{ fontFamily: "Comfortaa" }}
            className="text-l text-center lg:text-start mb-2 text-white font-semibold hover:underline hover:underline-offset-4"
          >
            <span className="font-light">&copy;</span>
            <a target="_blank" href="https://darn.dev/">
              Darn Development
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
