import React, { useState, useEffect } from "react";
import CaliforniAd from "../../assets/videos/californiaadd.mp4";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.3,
  });
  const slides = [CaliforniAd];
  const [t] = useTranslation("global");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]);

  const scrollToNextSection = () => {
    const nextSection = document.querySelector('.next-section');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div ref={ref} className="relative w-full h-screen overflow-hidden">
      {slides.map((slide, index) => (
        <div
          key={index}
          className="absolute top-0 left-0 w-full h-full"
          style={{
            opacity: index === currentIndex ? 1 : 0,
            transition: "opacity 1.5s ease-in-out",
          }}
        >
          {/* Video Background */}
          <video
            playsInline
            autoPlay
            muted
            loop
            preload="auto"
            className="absolute top-0 left-0 w-full h-full object-cover transform transition-transform duration-[30s]"
            style={{ transform: isVisible ? 'scale(1.1)' : 'scale(1)' }}
            onLoadStart={(e) => e.target.play()}
          >
            <source src={slide} type="video/mp4" />
          </video>

          {/* Overlay Gradient */}
          <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/50 to-black/90">
            {/* Content Container */}
            <div className="absolute inset-0 flex flex-col justify-center items-center px-4 pb-20 md:pb-0">
              {/* Decorative Elements */}
              <div className="relative mb-6 md:mb-8">
                <div className={`w-[2px] h-16 md:h-20 bg-primaryYellow transition-all duration-1000 
                  ${isVisible ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"}`}
                />
                <div className={`absolute top-0 -left-8 md:-left-12 w-8 md:w-12 h-[2px] bg-primaryYellow transition-all delay-500 duration-700 
                  ${isVisible ? "scale-x-100 opacity-100" : "scale-x-0 opacity-0"}`}
                />
                <div className={`absolute bottom-0 -right-8 md:-right-12 w-8 md:w-12 h-[2px] bg-primaryYellow transition-all delay-500 duration-700 
                  ${isVisible ? "scale-x-100 opacity-100" : "scale-x-0 opacity-0"}`}
                />
              </div>

              {/* Main Content */}
              <div className="text-center w-full max-w-5xl mx-auto px-4">
                {/* Title */}
                <h1
                  style={{ fontFamily: "Comfortaa" }}
                  className="relative overflow-hidden uppercase font-black text-3xl sm:text-4xl md:text-5xl lg:text-8xl mb-4 md:mb-6"
                >
                  <span className={`block transition-all duration-1000 ${
                    isVisible ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
                  }`}>
                    <span className="text-white">California</span><> </><br className="md:block hidden" />
                    <span className="text-primaryYellow relative">
                      {t("ImageCarousel.title")}
                      <span className="absolute -bottom-2 md:-bottom-3 left-0 w-full h-1 bg-primaryYellow transform origin-left transition-transform duration-700 delay-700"
                        style={{ transform: isVisible ? 'scaleX(1)' : 'scaleX(0)' }}
                      />
                    </span>
                  </span>
                </h1>

                {/* Subtitle */}
                <p
                  style={{ fontFamily: "Comfortaa" }}
                  className={`text-base sm:text-lg md:text-xl lg:text-3xl text-white/90 mt-4 md:mt-8 mx-auto text-center font-light max-w-3xl leading-relaxed
                    transition-all duration-[2000ms] ${
                    isVisible
                      ? "translate-y-0 opacity-100"
                      : "translate-y-10 opacity-0"
                  }`}
                >
                  {t("ImageCarousel.quote")}
                </p>

                {/* CTA Button */}
                <button
                  onClick={scrollToNextSection}
                  className={`inline-block mt-8 md:mt-16 px-6 md:px-10 py-3 md:py-4 
                    text-white border-2 border-primaryYellow rounded-full
                    relative overflow-hidden group transition-all duration-300 
                    hover:scale-105 cursor-pointer z-20
                    ${isVisible ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"}`}
                  style={{ 
                    fontFamily: "Comfortaa",
                    transitionDelay: "1s"
                  }}
                >
                  <span className="absolute inset-0 bg-primaryYellow transform 
                    -translate-x-full group-hover:translate-x-0 transition-transform 
                    duration-500 z-10"/>
                  <span className="relative z-20 text-sm md:text-lg 
                    group-hover:text-black transition-colors duration-300">
                    {t("ImageCarousel.buttonText")}
                  </span>
                </button>
              </div>

              {/* Scroll Indicator */}
              <div className={`absolute bottom-32 md:bottom-12 left-1/2 transform -translate-x-1/2
                transition-all duration-1000 ${
                  isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
                }`}
              >
                <div className="flex flex-col items-center gap-2 md:gap-3">
                  <div className="w-[2px] h-20 md:h-20 bg-white/20 relative overflow-hidden rounded-full">
                    <div className="absolute top-0 left-0 w-full h-full bg-primaryYellow animate-scrollDown"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageCarousel;
