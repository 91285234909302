import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import AminitiesCard from "./AmenitiesCard";
import Image1 from "../../assets/images/california-3.jpeg";
import Image2 from "../../assets/images/california-11.jpeg";
import Image3 from "../../assets/images/california-5.jpeg";
import Image4 from "../../assets/images/california-7.jpeg";
import Image5 from "../../assets/images/califronia-12.jpeg";
import Image6 from "../../assets/images/california-2.jpeg";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const AminitiesSection = () => {
  const[t,i18next] = useTranslation("global");
  const [isVisible, setIsVisible] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 70% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
    }
  }, [inView]); // Run only when 'inView' changes
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const amenitiesData = [
    { image: Image1, label: "Fitness" },
    { image: Image2, label: "Cardio" },
    { image: Image3, label: "MMA" },
    { image: Image4, label: "Cross Fit" },
    { image: Image5, label: "Boxing" },
    { image: Image6, label: "Stretching" }
  ];

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="relative min-h-screen py-20 bg-black overflow-hidden"
    >
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-primaryYellow/20 via-transparent to-transparent blur-3xl opacity-30" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Header Section */}
        <motion.div 
          variants={cardVariants}
          className="text-center mb-20"
        >
          <h1 
            style={{ fontFamily: "Comfortaa" }}
            className="text-5xl md:text-6xl font-bold text-white mb-6"
          >
            {t("AmenitiesSection.title")}
          </h1>
          <p
            style={{ fontFamily: "Comfortaa" }} 
            className="text-xl text-white/80 max-w-2xl mx-auto px-4"
          >
            {t("AmenitiesSection.description")}
          </p>
          <motion.div 
            className="w-[2px] h-20 bg-gradient-to-b from-primaryYellow via-primaryYellow to-transparent mx-auto mt-8"
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          />
        </motion.div>

        {/* Desktop Grid */}
        <div className="hidden lg:flex flex-col items-center">
          <motion.div 
            variants={containerVariants}
            className="grid grid-cols-3 gap-8 mb-8"
          >
            {amenitiesData.slice(0, 3).map((item, index) => (
              <motion.div key={index} variants={cardVariants}>
                <AminitiesCard image_path={item.image} buttonLabel={item.label} />
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            variants={containerVariants}
            className="grid grid-cols-3 gap-8"
          >
            {amenitiesData.slice(3).map((item, index) => (
              <motion.div key={index + 3} variants={cardVariants}>
                <AminitiesCard image_path={item.image} buttonLabel={item.label} />
              </motion.div>
            ))}
          </motion.div>
        </div>

        {/* Mobile Grid */}
        <motion.div 
          variants={containerVariants}
          className="lg:hidden grid grid-cols-1 sm:grid-cols-2 gap-6"
        >
          {amenitiesData.map((item, index) => (
            <motion.div 
              key={index}
              variants={cardVariants}
              className="flex justify-center"
            >
              <AminitiesCard 
                image_path={item.image} 
                buttonLabel={item.label}
                className="w-full max-w-sm" // Add max width for better mobile display
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AminitiesSection;