import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = () => {
  return (
    <Helmet>
      <title>California Fitness Center | Best Gym in Kumanovo & Macedonia</title>
      <meta name="description" content="Premier fitness center in Kumanovo, Macedonia. Professional trainers, modern equipment, and diverse fitness programs including MMA, CrossFit, Boxing, and more. Join the best gym in Macedonia!" />
      
      {/* Keywords */}
      <meta name="keywords" content="fitness center kumanovo, teretana kumanovo, gym kumanovo, fitness macedonia, california fitness, best gym macedonia, teretana makedonija, fitness centar kumanovo, personal trainer kumanovo, crossfit kumanovo, mma kumanovo, boxing kumanovo" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="California Fitness Center - Premier Gym in Kumanovo" />
      <meta property="og:description" content="Join the best-equipped fitness center in Kumanovo. Professional trainers, modern facilities, and diverse fitness programs." />
      <meta property="og:image" content="/path-to-your-gym-image.jpg" />
      
      {/* Location Schema */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "SportsActivityLocation",
            "name": "California Fitness Center",
            "image": "/path-to-your-gym-image.jpg",
            "description": "Premier fitness center in Kumanovo offering professional training, modern equipment, and diverse fitness programs.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Hristijan Todorovski Karposh 60",
              "addressLocality": "Kumanovo",
              "addressRegion": "Kumanovo",
              "postalCode": "1300",
              "addressCountry": "MK"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 42.13311859940979,
              "longitude": 21.710561375573892
            },
            "telephone": "+38971255244",
            "openingHours": "Mo-Su 06:00-23:00"
          }
        `}
      </script>
    </Helmet>
  );
};

export default MetaTags;