import React from "react";
import { motion } from "framer-motion";

const Card = (props) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
      className="group relative w-80 h-64 rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-500"
    >
      <div 
        className="absolute inset-0 bg-cover bg-center transition-transform duration-700 group-hover:scale-110"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
        }}
      />
      
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/90 group-hover:from-black/80 group-hover:to-black/95 transition-all duration-500" />

      {/* Content Container */}
      <div className="relative h-full flex flex-col justify-center items-center p-6 z-10">
        {/* Decorative Line */}
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 w-16 h-1 bg-primaryYellow rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500" />

        {/* Title */}
        <div className="transform group-hover:-translate-y-2 transition-all duration-500">
          <h1 className="text-primaryYellow text-6xl font-black mb-4 tracking-wider">
            {props.title}
          </h1>
        </div>

        {/* Subtitle */}
        <div className="transform translate-y-4 group-hover:translate-y-0 opacity-0 group-hover:opacity-100 transition-all duration-500">
          <h2 
            style={{ fontFamily: "Comfortaa" }}
            className="text-white text-xl font-bold uppercase tracking-wider text-center"
          >
            {props.button_txt}
          </h2>
        </div>

        {/* Bottom Accent */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-16 h-1 bg-primaryYellow rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500" />
      </div>
    </motion.div>
  );
};

export default Card;
